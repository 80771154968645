import React from 'react'

import './styles.css'
export const Textaria = ({ className = '', placeholder = '', rows = 6 ,    value = '',
                             field = '',
                             onChange}) => {
  return (
    <div className={className}>
      <div className="w-full field-textarea-container border-[1.5px] rounded-[8px] border-gray-100 focus:ring-[1.5px] focus:ring-gray-100">
        <textarea
          className="field-textarea md:text-base text-[14px]"
          id="inputid"
          name="inputName"
          placeholder=" "
          rows={rows}
          value={value}
          onChange={(e) => onChange(e.target.value, field)}
        />
        <label className="field-textarea-placeholder" htmlFor="inputName">
          {placeholder}
        </label>
      </div>
    </div>
  )
}

export default Textaria
