import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "@components/Layout";
import PageHeaderWrapper from "@components/PageHeaderWrapper";
import { Heading } from "@components/Typography";

import { ScrollAnimation } from "@components/ScrollAnimation";
import { Button } from '@components/Button'
import { TextField } from '@components/TextField'
import { Textaria } from '@components/Textaria'
import { SelectOption } from '@components/SelectOption'

import ProductHeaderImage from "@assets/img/header/contact.png";
import ProductHeaderImageMobile from "@assets/img/header/contact-mobile.png";
import IconSend from "@assets/img/icon-send.png";
import IconEmail from "@assets/img/icon-email.png";
import IconPhone from "@assets/img/icon-phone.png";
import {sendFeedBack} from "../components/action";
import { useTranslation } from 'react-i18next'
const ContactPage = () => {
  const data = useStaticQuery(query);
  const { t, i18n } = useTranslation()
  const pageTrans = 'contact:'

  const info = {
    email: "",
    fullName: "",
    phone: "",
    userRole:1,
    content:""
  }

  const errorMsg={
    email: "",
    fullName: "",
    phone: "",
    content:""
  }
  const result = {
    error:"",
    msg:""
  };
  const processing = 0;
  const [contactData, setContactData] = useState(info);
  const [error, setMessage] = useState(errorMsg);
  const [loading, setProcessing] = useState(processing);
  const [msgResult, setResult] = useState(result);

  const typesUser = [
    {
      value:1,
      nameEN: "Doctor",
      nameVI: "Bác sĩ",
    },
    {
      value:2,
      nameEN: "Patient",
      nameVI: "Bệnh nhân",
    }
  ]
  const isEmail = (str) => {
    return new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/).test(str)
  }
  const isNumber = (str) => {
    return new RegExp(/^\d+$/).test(str)
  }
  const updateField = (value, field) => {
    console.log(field);
    if (field == 'userRole') {
      setContactData({ ...contactData, [field]: +value});
    } else {
      setContactData({ ...contactData, [field]: value});
    }
    if (field == 'email') {
      if (value == '') {
        setMessage({...error, [field]:"error.required"})
      }else if (!isEmail(value)) {
        setMessage({...error, [field]: "error.invalidEmail"});
      }else {
        setMessage({...error, [field]: ""})
      }
    }
    if (field == 'phone') {
      if (value == '') {
        setMessage({ ...error, [field]:"error.required"})
      }else if (!isNumber(value)|| value.length<6){
        setMessage({ ...error, [field]: "error.invalidPhone"});
      }else setMessage({ ...error, [field]:""})
    }
    if (field == 'fullName') {
      if (value == '') {
        setMessage({ ...error, [field]:"error.required"})
      }else setMessage({ ...error, [field]:""})
    }
    if (field == 'content') {
      if (value == '') {
        setMessage({ ...error, [field]:"error.required"})
      }else if (value.length>2000||value.length<10) {
        setMessage({ ...error, [field]:"error.invalidFeedback"})
      }else setMessage({ ...error, [field]:""})
    }
  }

  const sendForm=(info)=>{
    if (error.phone || error.email || error.content || error.fullName ) {
      return;
    } else {
      console.log(info);
      if (info.phone==''&&info.content==''&&info.email==''&&info.fullName=='') {
        setMessage(
            {
              email: "error.required",
              fullName: "error.required",
              phone: "error.required",
              content:"error.required"
            }
        )
        setResult({msg: "", error: ""})
        return;
      }
      setProcessing(1);
      sendFeedBack(info).then(response=>{
        let result = response.data;
        if (result.error.code == 1) {
          setResult({ msg: `${pageTrans}section1.thanks`,error: 0});
          setContactData({
            email: "",
            fullName: "",
            phone: "",
            userRole:1,
            content:""
          })
        } else {
          setResult({ msg: result.error.msg,error:1});
        }
        setProcessing(0);
      });

    }
  }

  const clearText = (field) => {
    setContactData({ ...contactData, [field]: ""});
    setMessage({...error,[field]:"This field is required!"});
  }

  return (
    <Layout seo={data.strapiHomepage.seo} navbarTheme="light">
       <PageHeaderWrapper
        className="bg-center bg-no-repeat relative sm:bg-cover md:block hidden"
        style={{ backgroundImage: `url(${ProductHeaderImage})` }}
      >
        <div className="bg-blue-800 bg-opacity-80 absolute top-0 left-0 right-0 bottom-0" />
        <div className="relative z-10 text-white flex justify-center items-center flex-col h-[342px] sm:h-[414px] sm:pb-[115px]">
          <Heading h={3} className="sm:mb-[4px] animate__animated animate__fadeInUp text-3xl pt-8 font-bold" style={{ marginBottom: 10 }}>{t('menu.contact')}</Heading>
          <p className="animate__animated animate__fadeInUp animate__delay-2s tracking-wider">{t('menu.home')} <span className="inline-block scale-[1.5] font-thin">/</span> {t('menu.contact')}</p>
        </div>
      </PageHeaderWrapper>

      <PageHeaderWrapper
        className="bg-center bg-no-repeat relative sm:bg-cover md:hidden block"
        style={{ backgroundImage: `url(${ProductHeaderImageMobile})` }}
      >
        <div className="bg-blue-800 bg-opacity-80 absolute top-0 left-0 right-0 bottom-0" />
        <div className="relative z-10 text-white flex justify-center items-center flex-col h-[342px] sm:h-[414px] sm:pb-[115px]">
          <Heading h={3} className="sm:mb-[4px] animate__animated animate__fadeInUp text-3xl pt-8 font-bold" style={{ marginBottom: 10 }}>{t('menu.contact')}</Heading>
          <p className="animate__animated animate__fadeInUp animate__delay-2s tracking-wider">{t('menu.home')} <span className="inline-block scale-[1.5] font-thin">/</span> {t('menu.contact')}</p>
        </div>
      </PageHeaderWrapper>

      <div className="container md:mt-24 md:pt-1 md:pb-24">
        <div className="md:grid md:grid-cols-12">
          <div className="md:col-start-6 md:col-end-13 order-2">
            <section>
              <div className="">
                <ScrollAnimation className="">
                  <Heading
                    h={6}
                    className=" md:text-3xl  md:font-bold font-bold text-3xl text-blue-700 leading-[45px] mb-6 mt-12 md:mb-0 md:mt-0"
                  >
                    {t(`${pageTrans}section1.heading`)}
                  </Heading>
                </ScrollAnimation>
                <ScrollAnimation>
                  <p className="md:mt-4 text-lg">
                    {t(`${pageTrans}section1.content1`)}
                  </p>
                </ScrollAnimation>

                <div className="md:grid md:grid-cols-12 md:mt-8 mt-6 md:gap-6">
                  <div className="md:col-span-6 ">
                    <ScrollAnimation className=" md:mt-0 mt-4">
                      <TextField type="text" placeholder={t(`${pageTrans}section1.placeholder1`)} className="w-full" field='fullName' value={contactData.fullName} onChange={updateField} clearText={clearText}/>
                    </ScrollAnimation>
                    <p className="text-red-500 mt-[8px]">{t(error.fullName)}</p>
                  </div>
                  <div className="md:col-span-6">
                    <ScrollAnimation className=" md:mt-0 mt-4">
                      <TextField type="email" placeholder={t(`${pageTrans}section1.placeholder2`)} className="w-full" field='email' value={contactData.email} onChange={updateField} clearText={clearText}/>
                    </ScrollAnimation>
                    <p className="text-red-500 mt-[8px]">{t(error.email)}</p>
                  </div>
                  <div className="md:col-span-6">
                    <ScrollAnimation className=" md:mt-0 mt-4">
                      <TextField type="text" placeholder={t(`${pageTrans}section1.placeholder3`)} className="w-full" field='phone' value={contactData.phone} onChange={updateField} clearText={clearText}/>
                    </ScrollAnimation>
                    <p className="text-red-500 mt-[8px]">{t(error.phone)}</p>
                  </div>
                  <div className="md:col-span-6">
                    <ScrollAnimation className=" md:mt-0 mt-4">
                      <SelectOption items={typesUser} placeholder={t(`${pageTrans}section1.placeholder4`)} className="w-full" field='userRole' onChange={updateField} lang={i18n.language} />
                    </ScrollAnimation>
                  </div>
                  <div className="md:col-span-12">
                    <ScrollAnimation className=" md:mt-0 mt-4">
                      <Textaria placeholder={t(`${pageTrans}section1.placeholder5`)} className="w-full" field='content' value={contactData.content} onChange={updateField} />
                    </ScrollAnimation>
                    <p className="text-red-500 mt-[8px]">{t(error.content)}</p>
                  </div>
                </div>

                <ScrollAnimation className=" mt-3 justify-center md:mt-2">
                  <Button size="lg" className=" relative md:mt-[24px] bg-blue mt-5 w-full h-[54px] md:w-[379px]" onClick={()=>sendForm(contactData)}>
                    <img src={IconSend} alt="" width="24" height="24" className="mr-[13.8px]"/>
                    {t(`${pageTrans}section1.cta1`)}
                    {loading==1 &&
                     <svg className=" absolute animate-spin right-[50px] top-[16px] h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                          fill="none" viewBox="0 0 24 24">
                       <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                               strokeWidth="4"></circle>
                       <path className="opacity-75" fill="currentColor"
                             d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                     </svg>
                    }
                  </Button>
                </ScrollAnimation>
                <div className={`text-center mt-[24px]  ${msgResult.error==1?"text-red-400":"text-green-400"}`}>
                  {msgResult.error===1&&
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                  }
                  {msgResult.error===0&&
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>
                  }
                  {
                    <p className="inline-block ml-[8px]">{t(msgResult.msg)}</p>
                  }
                </div>
              </div>
            </section>
          </div>
          <div className="md:col-start-1 md:col-end-6 order-1">
            <section>
              <div className="pb-14 mb-[-2px]">
                <ScrollAnimation>
                  <Heading
                    h={6}
                    className="md:text-3xl sm:font-bold font-bold text-2xl text-blue-700 leading-[45px] mb-2 mt-8 pt-3 md:mt-0 md:pt-0"
                  >
                    {t(`${pageTrans}section2.heading`)}
                  </Heading>
                </ScrollAnimation>
                <ScrollAnimation>
                  <ul>
                    <li className="block text-base py-[9px]">
                      <img src={IconPhone} alt="" className="inline-block mr-[13px]"/>
                      <span>US Phone: </span>
                      <span>1-415-497-1516</span>
                    </li>
                    <li className="block text-base py-[9px]">
                      <img src={IconPhone} alt="" className="inline-block mr-[13px]"/>
                      <span>VN Phone: </span>
                      <span>+84 909499341</span>
                    </li>
                    <li className="block text-base py-[9px]">
                      <img src={IconEmail} alt="" className="inline-block mr-[13px]"/>
                      <span>Email: </span>
                      <a href="mailto:contact@imi.ai" className="text-blue-500">contact@imi.ai</a>
                    </li>
                  </ul>
                </ScrollAnimation>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const query = graphql`
  query {
    strapiHomepage {
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;

export default ContactPage;
 