import React from 'react'

import IconRemove from '@assets/img/icon-remove.png'

import './styles.css'
export const TextField = ({
  className = '',
  placeholder = '',
  type = 'text',
  value = '',
  field = '',
  onChange,
  clearText
}) => {
  return (
    <div className={className}>
      <div className="field-container relative border-[1.5px] rounded-[8px] border-gray-100 focus:ring-[1.5px] h-[54px] w-full focus:ring-gray-100 pr-[12px] pl-[12px] block  ">
        {value && value.length > 0 && (
          <img
            src={IconRemove}
            className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer"
            onClick={() => clearText(field)}
          />
        )}
        <input
          className="field-input md:text-base text-[14px] focus:outline-none focus:border-transparent  w-full box-border p-[5px] mt-[17px] border-[none] bottom-0 block"
          id="inputid"
          name="inputName"
          type={type}
          placeholder=" "
          value={value}
          onChange={(e) => onChange(e.target.value, field)}
        />
        <label
          className="field-placeholder md:text-base text-[14px] absolute top-1/2 pointer-events-none text-gray-100 top-1/2 pointer-events-none"
          htmlFor="inputName"
        >
          {placeholder}
        </label>
      </div>
    </div>
  )
}

export default TextField
