import React from 'react'

import './styles.css'
export const SelectOption = ({ className = '', placeholder = '', items = [], field = '',
                                 onChange, lang = 'en' }) => {
  return (
    <div className={className}>
      <div className="w-full relative border-[1.5px] rounded-[8px] border-gray-100 focus:ring-[1.5px] h-[54px] focus:ring-gray-100 p-0 pr-[10px] pl-[12px]">
        <select className="field-select focus:outline-none focus:border-transparent appearance-none bg-transparent md:text-base text-[14px] ml-[4px] pr-[10px] pt-[5px] rounded-[10px] border-[none] mt-[17px] block w-full bottom-0 box-border bg-no-repeat" onChange={(e) => onChange(e.target.value, field)}>
          {items.map((item, index) => {
            return (
              <option value={item.value} key={index}>
                {item[`name${lang.toUpperCase()}`]}
              </option>
            )
          })}
        </select>
        <label className="field-select-placeholder absolute top-1/2 md:text-base text-[14px]" htmlFor="inputName">
          {placeholder}
        </label>
      </div>
    </div>
  )
}

export default SelectOption
